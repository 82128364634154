import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "../FontAwesomeIcon";
import { TableCell, TableFooter, TableRow, Grid } from "@material-ui/core";
import { Box } from "@mui/system";
import MaterialChip from "@material-ui/core/Chip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

const useStyles = makeStyles((theme) => ({
  centeredHeader: {
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "4px",
    },
  },
}));

const Acronimo = (x) => {
  const Chip = (props) => {
    const StyledChip = withStyles({
      root: {
        color: x.color,
        borderRadius: 3,
        backgroundColor: x.background,
        fontSize: "60%",
        align: "left",

        "&:hover": {
          backgroundColor: x.background,
          filter: "brightness(120%)",
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: x.background,
          borderColor: props.color,
        },
      },
      outlined: {
        color: x.color,
        border: `2px solid ${props.color}`,
        backgroundColor: x.background,
        borderRadius: 1,
      },
    })(MaterialChip);

    return <StyledChip {...props} />;
  };

  return (
    <Box
      sx={{
        height: 1,
        width: "100%",
        align: "left",
      }}
    >
      <Grid container>
        <Grid container flex-start="left" spacing={1}>
          <Grid item flex-start="left">
            <Chip size="small" label={x.acronym} />
          </Grid>

          <Grid item>
            <Grid item>{x.name}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Tabla = ({ data, icons, totalGeneral }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "4px",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: "4px",
            },
          },
        },
      },
    });
  const classes = useStyles();

  const datos = [
    {
      name: "emitterName",
      label: "Emisor",
      options: {
        filter: true,
        display: true,
        sort: true,

        customBodyRenderLite: (value) => (
          <Box sx={{ align: "left" }}>{Acronimo(data[value].emitter)}</Box>
        ),
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),

        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },
      },
    },

    {
      name: "reports",
      label: "Informes",
      options: {
        filter: true,
        display: true,
        sort: true,

        customHeadLabelRender: (columnMeta) => {
          return <b>{columnMeta.label}</b>;
        },

        customBodyRenderLite: (value) => (
          <div align="center">{data[value].reports}</div>
        ),
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
      },
    },
    {
      name: "recommendations",
      label: "Recomendaciones",
      options: {
        filter: true,
        display: true,

        customHeadLabelRender: (columnMeta) => {
          return <b style={{ align: "center" }}>{columnMeta.label}</b>;
        },

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        customBodyRenderLite: (value) => (
          <div align="center">{data[value].recommendations}</div>
        ),
      },
    },
    {
      name: "attentions",
      label: "Atenciones",
      options: {
        filter: true,
        display: true,
        sort: true,

        customHeadLabelRender: (columnMeta) => {
          return <b style={{ align: "right" }}>{columnMeta.label}</b>;
        },

        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),

        customBodyRenderLite: (value) => (
          <div align="center">{data[value].attentions}</div>
        ),
      },
    },
  ];

  if (data[0] !== undefined) {
    Object.keys(data[0]).forEach((x) => {
      if (
        x !== "emitter" &&
        x !== "emitterName" &&
        x !== "recommendations" &&
        x !== "attentions" &&
        x !== "reports"
      ) {
        let nombre = "";
        icons.forEach((x1) => {
          if (x1.id === x) {
            nombre = x1.name;
          }
        });

        datos.push({
          name: x,
          label: nombre,
          options: {
            filter: true,
            display: true,

            setCellProps: () => ({
              style: { minWidth: "4px", maxWidth: "4px" },
            }),

            customBodyRenderLite: (value) => (
              <div align="center">{data[value][x]}</div>
            ),

            customHeadLabelRender: (columnMeta, value) => {
              const item = [];

              icons.forEach((x1) => {
                if (x1.id === x) {
                  item.push(x1);
                }
              });

              if (item[0] !== undefined) {
                return <FontAwesomeIcon item={item[0]} />;
              } else {
                return "";
              }
            },
          },
        });
      }
    });
  }
  const options = {
    expandableRowsOnClick: false,
    responsive: "standard",
    searchPlaceholder: "Buscar ",
    tableBodyHeight: "estándar",
    filterType: "checkbox",
    tableBodyMaxHeight: "estándar",
    onRowsPerPageChange: 10,
    print: false,
    viewColumns: true,
    filter: false,
    selectableRows: "none",
    download: true,

    setTableProps: () => {
      return {
        // material ui v5 only
        size: "small",
      };
    },

    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].label] = v;
        });
        result.push(temp);
        return result;
      }, []);

      const fileName = "Asignaciones";
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },

    textLabels: {
      body: {
        noMatch: "Registros no encontrados",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        sortable: true,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        onPageChange: true,
      },
      toolbar: {
        search: "Buscar",
        viewColumns: "Ver columnas",
        downloadCsv: "Descargar",
        filterTable: "Filtrar",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Reiniciar",
      },

      viewColumns: {
        title: "Mostrar columnas",
      },
    },
    customTableBodyFooterRender: (opts) => {
      return (
        <>
          {data.length > 0 && (
            <TableFooter // className={classes.footerCell}
            >
              <TableRow>
                {opts.columns.map((col, index) => {
                  if (col.name === "emitterName") {
                    return (
                      <TableCell
                        align="center"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {"Total"}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "reports") {
                    return (
                      <TableCell
                        align="center"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {totalGeneral.planes.toLocaleString()}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "recommendations") {
                    return (
                      <TableCell
                        align="center"
                        key={index}
                        // className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {totalGeneral.hallazgos.toLocaleString()}
                        </p>
                      </TableCell>
                    );
                  } else if (col.name === "attentions") {
                    return (
                      <TableCell
                        align="center"
                        key={index}
                        //  className={classes.footerCell}
                      >
                        <p
                          style={{
                            color: "black",
                            fontWeight: "bolder",
                            fontSize: "14px",
                          }}
                        >
                          {totalGeneral.atenciones.toLocaleString()}
                        </p>
                      </TableCell>
                    );
                  } else {
                    for (let i = 0; i < icons.length; i++) {
                      if (col.name === icons[i].id) {
                        return (
                          <TableCell
                            align="center"
                            key={index}
                            //  className={classes.footerCell}
                          >
                            <p
                              style={{
                                color: "black",
                                fontWeight: "bolder",
                                fontSize: "14px",
                              }}
                            >
                              {totalGeneral[icons[i].id].toLocaleString()}
                            </p>
                          </TableCell>
                        );
                      }
                    }
                  }

                  return null;
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable data={data} columns={datos} options={options} />
    </ThemeProvider>
  );
};

export default Tabla;
