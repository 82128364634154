import Form from "react-bootstrap/Form";
import { Grid } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import { Box } from "@mui/system";
import Cheched from "./Checked";
import AutoCompletarConChecboxMultipleAndCards from "../../SelectAutoCompletar/AutoCompletarConChecboxMultipleAndCards";
import AutoCompletarConChecboxMultipleAtenciones from "../../SelectAutoCompletar/AutoCompletarConChecboxMultipleAtenciones";
import AutoCompletarNormalNingunoSinX from "../../SelectAutoCompletar/AutoCompletarNormalNingunoSinX";
import CustomizedTreeView from "./CustomizedTreeView";
import PriorizacionCheckBox from "./PriorizacionCheckBox";
import AutoCompletarMultipleCheckBox from "./AutoCompletarMultipleCheckBox";
import { useState } from "react";

const FormModal = ({
  onSubmit,
  register,
  handleSubmit,
  filtro,
  sisReferencia,
  años,
  arbol,
  setContFecha,
  setContEstado,
  setContEval,
  dataEvaluador,
  unidades,
  unidad,
  handleChangeUnidad,
  unidadesData,
  personName,
  setPersonName,
  personNameAño,
  setPersonNameAño,
  personNamePrio,
  setPersonPrio,
  personNameEstado,
  setPersonNameEstado,
  personNameEvaluador,
  setPersonNameEvaluador,
  checkedArrayStates,
  checkedArrayTypes,
  checkedLevels,
  objFiltro,
  prioritizacion,
  prueba,
}) => {
  const [estado, setEstado] = useState(["a"]);
  const handleChangePrio = (event, value) => {
    if (value !== null && value !== undefined) {
      setPersonPrio(value);
      register("prioritization", { value: value.id });
    } else {
      register("prioritization", { value: [] });
    }
  };

  const handleChangeReferencia = (event, value) => {
    let cont = 0;
    let todosValue = false;

    for (let i = 0; i < value.length; i++) {
      if (value[i].id === "Todos") {
        todosValue = true;
        cont = cont + 1;
        break;
      }
    }
    if (todosValue) {
      setPersonName(sisReferencia.filter((animal) => animal.id !== "Todos"));
    }
    if (cont === 0) {
      setPersonName(value);
    }
  };

  const handleChangeAño = (event, value) => {
    let contador = 0;
    let todo = false;

    let todosValue = false;

    for (let i = 0; i < value.length; i++) {
      if (value[i].id === "Todos") {
        todosValue = true;

        break;
      }
    }

    if (personNameAño.length > 0) {
      for (let i = 0; i < personNameAño.length; i++) {
        if (personNameAño[i].id === "Todos") {
          todo = true;
          break;
        }
      }
    }

    if (value.length > 0 && todo === false) {
      // Si no viene un todo de atras

      if (todosValue) {
        // Si se seleccion todo en value

        setPersonNameAño(años.filter((animal) => animal.id));
        contador = contador + 1;
      } else {
        if (value.length + 1 !== años.length) {
          // Si no se selecciono todos
          setPersonNameAño(value.filter((animal) => animal.id));
          contador = contador + 1;
        } else {
          setPersonNameAño(años.filter((animal) => animal.id));
          contador = contador + 1;
        }
      }
    } else {
      // Si viene un todo de atras
      if (value.length > 0) {
        if (todosValue && value.length !== años.length) {
          setPersonNameAño(value.filter((animal) => animal.id !== "Todos"));
          contador = contador + 1;
        } else {
          setPersonNameAño([]);
          contador = contador + 1;
        }
      }
    }

    if (contador === 0) {
      setPersonNameAño(value);
    }

    let cont = 0;
    const arr = [];

    if (value.length > 0) {
      if (value[0].id === "Todos") {
        años.forEach((x) => {
          arr[cont] = x.id;
          cont = cont + 1;
        });
      } else {
        value.forEach((x) => {
          arr[cont] = x.id;
          cont = cont + 1;
        });
      }
    }

    setContFecha(arr);
  };

  const handleChangeEstado = (event, value) => {
    setPersonNameEstado(value);

    let cont = 0;
    const arr = [];
    value.forEach((x) => {
      arr[cont] = x.id;
      cont = cont + 1;
    });

    setContEstado(arr);
    setEstado(arr);
  };
  register("states", { value: estado });
  const handleChangeEvaluador = (event, value) => {
    let contador = 0;
    let todo = false;

    let todosValue = false;

    for (let i = 0; i < value.length; i++) {
      if (value[i].id === "Todos") {
        todosValue = true;

        break;
      }
    }

    if (personNameAño.length > 0) {
      for (let i = 0; i < personNameEvaluador.length; i++) {
        if (personNameEvaluador[i].id === "Todos") {
          todo = true;
          break;
        }
      }
    }

    if (value.length > 0 && todo === false) {
      // Si no viene un todo de atras

      if (todosValue) {
        // Si se seleccion todo en value

        setPersonNameEvaluador(dataEvaluador.filter((animal) => animal.id));
        contador = contador + 1;
      } else {
        if (value.length + 1 !== dataEvaluador.length) {
          // Si no se selecciono todos
          setPersonNameEvaluador(value.filter((animal) => animal.id));
          contador = contador + 1;
        } else {
          setPersonNameEvaluador(dataEvaluador.filter((animal) => animal.id));
          contador = contador + 1;
        }
      }
    } else {
      // Si viene un todo de atras
      if (value.length > 0) {
        if (todosValue && value.length !== dataEvaluador.length) {
          setPersonNameEvaluador(
            value.filter((animal) => animal.id !== "Todos")
          );
          contador = contador + 1;
        } else {
          setPersonNameEvaluador([]);
          contador = contador + 1;
        }
      }
    }

    if (contador === 0) {
      setPersonNameEvaluador(value);
    }
    let cont = 0;
    const arr = [];
    const id = [];

    if (value.length > 0) {
      if (value[0].id !== "Todos") {
        value.forEach((x) => {
          arr[cont] = x.name;
          id[cont] = x.id;
          cont = cont + 1;
        });
      } else {
        dataEvaluador.forEach((x) => {
          if (x.id !== "Todos") {
            arr[cont] = x.name;
            id[cont] = x.id;
            cont = cont + 1;
          }
        });
      }
    }
    setContEval(arr);
  };

  if (personNamePrio !== null && personNamePrio !== undefined) {
    register("prioritization_levels", { value: checkedLevels });
  } else {
    register("prioritization_levels", { value: [] });
  }

  let cont = 0;
  const arr = [];
  personNameAño.forEach((x) => {
    if (x.id !== "Todos") {
      arr[cont] = x.id;
      cont = cont + 1;
    }
  });
  register("years", { value: arr });

  let cont1 = 0;
  const arr1 = [];
  personNameEvaluador.forEach((x) => {
    if (x.id !== "Todos") {
      arr1[cont1] = x.id;
      cont1 = cont1 + 1;
    }
  });

  register("emitters", { value: arr1 });

  let cont2 = 0;
  const arr2 = [];

  personName.forEach((x) => {
    if (x.id !== "Todos") {
      arr2[cont2] = x.id;
      cont2 = cont2 + 1;
    }
  });

  register("systems", { value: arr2 });

  return (
    <Form autoComplete="off">
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
              <AutoCompletarConChecboxMultipleAtenciones
                data={sisReferencia}
                handleChange={handleChangeReferencia}
                valorDefecto={personName}
                placeholder={"Sistema de referencia"}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={7} md={6} zeroMinWidth>
            <FormControl></FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} zeroMinWidth>
            <Grid item xs={7} align="left">
              <Form.Text className="text-muted">Estado</Form.Text>
            </Grid>

            <Form.Group>
              <Grid item align="left">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: { md: "1fr 1fr 1fr" },
                      borderColor: "black",
                      p: 2,
                      border: "1px dashed grey",
                    }}
                  >
                    {filtro.attentions.states.map((item, index) => (
                      <div key={index}>
                        <Cheched
                          item={item}
                          register={register}
                          checkedArray={checkedArrayStates}
                          name={"attentions_states"}
                          objFiltro={objFiltro.attentions_states}
                        />
                      </div>
                    ))}
                  </Box>
                </Form.Group>
              </Grid>
            </Form.Group>
          </Grid>

          <Grid item xs={12} sm={12} md={12} zeroMinWidth>
            <Grid item xs={7} align="left">
              <Form.Text className="text-muted">Tipo</Form.Text>
            </Grid>

            <Form.Group>
              <Grid item align="left">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: { md: "1fr 1fr 1fr" },
                      borderColor: "black",
                      p: 3,
                      border: "1px dashed grey",
                    }}
                  >
                    {filtro.attentions.types.map((item, index) => (
                      <div key={index}>
                        <Cheched
                          item={item}
                          register={register}
                          checkedArray={checkedArrayTypes}
                          name={"attentions_types"}
                          objFiltro={objFiltro.attentions_types}
                        />
                      </div>
                    ))}
                  </Box>
                </Form.Group>
              </Grid>
            </Form.Group>
          </Grid>

          <Grid item xs={12} sm={12} md={12} zeroMinWidth>
            <Grid item xs={7} align="left">
              <Form.Text className="text-muted">
                Parámetros del informe
              </Form.Text>
            </Grid>

            <Form.Group>
              <Grid item align="left">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Box
                    sx={{
                      display: "grid",
                      borderColor: "black",
                      p: 1,
                      border: "1px dashed grey",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: { md: "1fr 1fr " },
                        borderColor: "black",
                      }}
                    >
                      <FormControl>
                        <Grid item align="left">
                          <Box
                            sx={{
                              p: 1,
                            }}
                          >
                            <AutoCompletarConChecboxMultipleAndCards
                              data={años}
                              handleChange={handleChangeAño}
                              valorDefecto={personNameAño}
                              placeholder={"Años"}
                            />
                          </Box>
                        </Grid>
                      </FormControl>

                      <FormControl>
                        <Grid item align="left">
                          <Box
                            sx={{
                              p: 1,
                            }}
                          >
                            <AutoCompletarConChecboxMultipleAndCards
                              data={filtro.states}
                              handleChange={handleChangeEstado}
                              valorDefecto={personNameEstado}
                              placeholder={"Estado del informe"}
                            />
                          </Box>
                        </Grid>
                      </FormControl>
                    </Box>

                    <FormControl fullWidth>
                      <Grid item align="left">
                        <Box
                          sx={{
                            display: "grid",
                            p: 2,
                          }}
                        >
                          <AutoCompletarConChecboxMultipleAndCards
                            data={dataEvaluador}
                            handleChange={handleChangeEvaluador}
                            valorDefecto={personNameEvaluador}
                            placeholder={"Evaluador"}
                          />
                        </Box>
                      </Grid>
                    </FormControl>
                  </Box>
                </Form.Group>
              </Grid>

              <Grid item xs={12} sm={12} md={12} zeroMinWidth>
                <CustomizedTreeView
                  arbol={arbol}
                  register={register}
                  name={"reports"}
                  objFiltro={objFiltro.reports}
                />
              </Grid>
            </Form.Group>
            <Grid item xs={12} sm={7} md={6} zeroMinWidth>
              <FormControl></FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl fullWidth>
                <AutoCompletarNormalNingunoSinX
                  data={prioritizacion}
                  handleChange={handleChangePrio}
                  valorDefecto={personNamePrio}
                  placeholder={"Sistema de priorización"}
                  label={"Sistema de priorización"}
                />
              </FormControl>

              <FormControl fullWidth>
                {personNamePrio !== null
                  ? personNamePrio.levels !== undefined
                    ? personNamePrio.levels.map((item, index) => (
                        <div key={index}>
                          <PriorizacionCheckBox
                            priorizacion={item}
                            index={index}
                            checkedLevels={checkedLevels}
                            personNamePrio={personNamePrio}
                            register={register}
                            objFiltro={objFiltro.prioritization_levels}
                          />
                        </div>
                      ))
                    : ""
                  : ""}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} zeroMinWidth>
              <FormControl fullWidth>
                <AutoCompletarMultipleCheckBox
                  data={unidadesData}
                  handleChange={handleChangeUnidad}
                  valorDefecto={unidad}
                  placeholder={"Filtro unidades"}
                />

                <Grid item xs={12} sm={7} md={6} zeroMinWidth>
                  <FormControl></FormControl>
                </Grid>
                <CustomizedTreeView
                  arbol={unidades}
                  register={register}
                  name={"units"}
                  objFiltro={objFiltro.units}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={7} md={6} zeroMinWidth>
              <FormControl></FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <div align="center">
        <LoadingButton
          loading={prueba}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </LoadingButton>
      </div>
    </Form>
  );
};
export default FormModal;
