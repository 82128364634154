import Cookies from "js-cookie";

export default async function getVisor() {
  const api = localStorage.getItem("api");
  const id = localStorage.getItem("id");
  const hash = localStorage.getItem("hash");
  const url = api + "file/" + id + "/" + hash;
  // "http://10.0.0.34/sgi/api/v4/commons/file/32191/c87b8a13b8cc9f49364b82f246188b54";

  // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwOi8vMTAuMC4wLjM0L3NnaS8iLCJhdWQiOiJzZ2kiLCJqdGkiOiJzZ2lkZW1vIiwiaWF0IjoxNjc5OTIwNzMxLjU5NDAyMSwibmJmIjoxNjc5OTIwNzMxLjU5NDAyMSwiZXhwIjoxNjg1Mjc5OTIwMDAwLCJhdWlkIjoiNjA0IiwiaWQiOiIzMjE5MiIsInRrIjoiOTIzN2Y0NjMyNTRjOTg3OTM4MWJjMTgyNTMwMDUxYjMiLCJ1dWlkIjoiOWNiNzAwOWUtMGQ0Zi00YjkwLWJhZTctYmQwNTczY2M2NmQxIn0.tLttDupM8KlZtpVd82pa-PXHsD7vqWR-p8S5cOcDXgQdso2wHSxxV1lJ5f0ssFbNbluCEbKTVi8XCIdJBpNYcQ

  const instance = localStorage.getItem("instance");
  const tkreact = "tkreact_" + instance;
  const jwt = "Bearer " + Cookies.get(tkreact);
  const header = {
    Authorization: jwt,
  };
  try {
    const config = { method: "GET", headers: header };
    const response = await fetch(url, config);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
