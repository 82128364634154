import React from "react";
import { useLottie } from "lottie-react";

const ErrorArchivo = ({ animationData }) => {
  /*   const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }; */

  const options = {
    animationData: animationData,
    style: {
      height: 500,
      width: 540,
      align: "center",
    },
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(options);

  return <div align="center">{View}</div>;
};

export default ErrorArchivo;
