import Cookies from "js-cookie";
export default async function postNotas(body) {
  // const endpoint = localStorage.getItem("api");

  const url = "http://10.0.0.34/sgi/api/v4/commons/annotations";

  const js = JSON.stringify(body);
  const instance = localStorage.getItem("instance");
  const tkreact = "tkreact_" + instance;
  const jwt = "Bearer " + Cookies.get(tkreact);

  const header = {
    "Content-Type": " application/json",
    Authorization: jwt,
  };

  try {
    const config = { method: "POST", headers: header, body: js };
    const response = await fetch(url, config);
    const json = await response.json();

    return json;
  } catch (error) {
    console.log("Error" + error);
  }
}
