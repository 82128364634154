import React from "react";
import usePostMetricas from "../../hooks/usePostMetricas";
import { CircularProgress } from "@material-ui/core";
import TablaMetricas from "../../components/Metricas/TablaMetricas";

const MetricasContainer = ({
  metricas,
  metricasValue,
  vista,
  año,
  atributoValue,
}) => {
  const { data } = usePostMetricas(
    metricas,
    metricasValue,
    vista,
    año,
    atributoValue
  );

  if (data) {
    return <TablaMetricas datosTabla={data} vista={vista} />;
  } else {
    return (
      <div align="center" style={{ marginTop: "25%" }}>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    );
  }
};

export default MetricasContainer;
