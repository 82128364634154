import Enrutador from "./ClasesYEmisoresDeDocumentos/components/Enrutador";
import EnrutadorProcesos from "./ParametrosProcesos/components/EnrutadorProcesos";
import EnrutadorDeObjetivos from "./ClasificacionDeObjetivosEstrategicos/components/EnrutadorDeObjetivos";
import TablaArchivosContainer from "./Archivos/pages/TablaArchivosContainer";
import EnrutadorRegistros from "./Ubicaciones/components/EnrutadorRegistros";
import { addEndPoint } from "./redux/reducers/MiCuenta/cuentaSlice";
import { useDispatch } from "react-redux";
import EnrutadorGlosario from "./Glosario/components/EnrutadorGlosario";
import {
  BrowserRouter,
  useLocation,
  Routes,
  Route,
  // Navigate,
  // useNavigate,
} from "react-router-dom";
// import Buscar from "./Archivos/components/Buscar";
import TablaSecurityHubContainer from "./SecurityHub/pages/TablaSecurityHubContainer";
import TablaUnidadesContainer from "./Unidades/pages/TablaUnidadesContainer";
import TablaPruebasContainer from "./Pruebas/pages/TablaPruebasContainer";
import TablaIpsContainer from "./ipsDynamo/pages/TablaIpsContainer";
// import ModalInicialContraseña from "./MiCuenta/components/ModalInicialContraseña";
import ContraseñaContainer from "./MiCuenta/pages/ContraseñaContainer";
/* import MiCuentaContainer from "./MiCuenta/pages/MiCuentaContainer"; */
import Cookies from "js-cookie";
import jwt1 from "jwt-decode";
import MiCuentaContainer from "./MiCuenta/pages/MiCuentaContainer";
import TablaVisorArchivosContainer from "./Sheets/pages/TablaVisorArchivosContainer.js";
import TablaReporteEncuestasContainer from "./ReporteEncuestas/pages/TablaReporteEncuestasContainer";
import TablaListadoDocumentosContainer from "./ReporteListadoDocumentos/pages/TablaListadoDocumentosContainer";
import TableroContainer from "./Tablero/pages/TableroContainer";
import TableroEventosContainer from "./TableroEventos/Eventos/pages/TableroEventosContainer";
import AtencionesContainer from "./Tablero Atenciones/Pages/AtencionesContainer";
import React, { useEffect, useState } from "react";
import Redirect from "./Redirect";
import NotFound from "./Error/NotFound";
import animationData from "./Error/notfound.json";
import VisorContainer from "./Visor-159/Page/VisorContainer";
import { Alert } from "@mui/material";
import TableroPddContainer from "./PDD/pages/TableroPddContainer";
import HighlighterContainer from "./Pdf-Highlighter/Page/HighlighterContainer";
import TablaEncuestasContainer from "./TableroEncuestas/Pages/TablaEncuestasContainer";
import QueryServiceJWT from "./QueryServiceJWT.js";
import { CircularProgress } from "@material-ui/core";

export default function QueryParamsExample() {
  return (
    <BrowserRouter>
      <QueryParamsDemo />
    </BrowserRouter>
  );
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function llamado(route, currentDateTime, instance) {
  if (
    localStorage.getItem("endpoint") !== "null" &&
    localStorage.getItem("endpoint") !== null &&
    localStorage.getItem("endpoint") !== undefined
  ) {
    if (route === "adm_archivos") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />

          <Routes>
            <Route path={"/"} element={<TablaArchivosContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "adm_ubicaciones") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<EnrutadorRegistros />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "adm_account") {
      if (
        localStorage.getItem("endpointCuenta") !== "null" &&
        localStorage.getItem("endpointCuenta") !== null &&
        localStorage.getItem("endpointCuenta") !== undefined &&
        localStorage.getItem("endpointCuenta") !== "undefined"
      ) {
        try {
          if (
            localStorage.getItem("tkreact") !== undefined &&
            localStorage.getItem("tkreact") !== "undefined" &&
            localStorage.getItem("tkreact") !== null &&
            localStorage.getItem("tkreact") !== "null"
          ) {
            const token = localStorage.getItem("tkreact");
            const prueba = jwt1(token);
            const date = new Date(prueba.exp * 1000);
            const date1 = new Date(prueba.nbf * 1000);
            const horaLocal = new Date();
            const moment = require("moment");
            const horaExp = moment(date).format("hh:mm");
            const horaNbf = moment(date1).format("hh:mm");
            const horaLcl = moment(horaLocal).format("hh:mm");
            if (horaNbf <= horaLcl && horaLcl <= horaExp) {
              return (
                <React.Fragment>
                  <Redirect instance={instance} route={route} />
                  <Routes>
                    <Route path="/" element={<MiCuentaContainer />} />
                  </Routes>
                </React.Fragment>
              );
            } else {
              const instance = localStorage.getItem("instance");
              const tkreact = "tkreact_" + instance;
              if (
                Cookies.get(tkreact) !== undefined &&
                Cookies.get(tkreact) !== "undefined" &&
                Cookies.get(tkreact) !== null &&
                Cookies.get(tkreact) !== "null"
              ) {
                return (
                  <React.Fragment>
                    <Redirect instance={instance} route={route} />
                    <Routes>
                      <Route path="/" element={<ContraseñaContainer />} />
                    </Routes>
                  </React.Fragment>
                );
              } else {
                return (
                  <div>
                    <NotFound animationData={animationData} />
                  </div>
                );
              }
            }
          } else {
            const instance = localStorage.getItem("instance");
            const tkreact = "tkreact_" + instance;
            if (
              Cookies.get(tkreact) !== undefined &&
              Cookies.get(tkreact) !== "undefined" &&
              Cookies.get(tkreact) !== null &&
              Cookies.get(tkreact) !== "null"
            ) {
              return (
                <React.Fragment>
                  <Redirect instance={instance} route={route} />
                  <Routes>
                    <Route path="/" element={<ContraseñaContainer />} />
                  </Routes>
                </React.Fragment>
              );
            } else {
              try {
                const token = localStorage.getItem("tkreact");
                const prueba = jwt1(token);
                const date = new Date(prueba.exp * 1000);
                const date1 = new Date(prueba.nbf * 1000);
                const horaLocal = new Date();
                const moment = require("moment");
                const horaExp = moment(date).format("hh:mm");
                const horaNbf = moment(date1).format("hh:mm");
                const horaLcl = moment(horaLocal).format("hh:mm");
                const instance = localStorage.getItem("instance");
                const tkreact = "tkreact_" + instance;
                if (
                  horaNbf <= horaLcl &&
                  horaLcl <= horaExp &&
                  Cookies.get(tkreact) !== undefined &&
                  Cookies.get(tkreact) !== "undefined" &&
                  Cookies.get(tkreact) !== null &&
                  Cookies.get(tkreact) !== "null"
                ) {
                  return (
                    <React.Fragment>
                      <Redirect instance={instance} route={route} />
                      <Routes>
                        <Route path="/" element={<MiCuentaContainer />} />
                      </Routes>
                    </React.Fragment>
                  );
                } else {
                  // localStorage.setItem("tkreact", null);
                  return (
                    <div>
                      <NotFound animationData={animationData} />
                    </div>
                  );
                }
              } catch (e) {
                // localStorage.setItem("tkreact", null);
                return (
                  <div>
                    <NotFound animationData={animationData} />
                  </div>
                );
              }
            }
          }
        } catch (e) {
          const instance = localStorage.getItem("instance");
          const tkreact = "tkreact_" + instance;
          if (
            Cookies.get(tkreact) !== undefined &&
            Cookies.get(tkreact) !== "undefined" &&
            Cookies.get(tkreact) !== null &&
            Cookies.get(tkreact) !== "null"
          ) {
            // localStorage.setItem("tkreact", null);
            document.location.href = `/?route=adm_account`;
          } else {
            // localStorage.setItem("tkreact", null);
            // condicionar a que se valla por la cookie o que saque el error
            return (
              <div>
                <NotFound animationData={animationData} />
              </div>
            );
          }
        }
      }
    }
    if (route === "adminairbytes") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaPruebasContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adm_documental") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<Enrutador />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adm_clasificacionitemplan") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<EnrutadorDeObjetivos />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "securityhub") {
      window.document.title = "Centro de seguridad";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaSecurityHubContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adm_paramprocesos") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<EnrutadorProcesos />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "visor-archivos") {
      window.document.title = "Visor de archivos";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<VisorContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adm_tableroatenciones") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<AtencionesContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "glosario") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<EnrutadorGlosario />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "surveys") {
      window.document.title = "Encuestas";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaEncuestasContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adm_unidades") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaUnidadesContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adminairbytes") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaPruebasContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "supplier") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TableroContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "events") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TableroEventosContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "rep_surveys") {
      window.document.title = "Reporte general de muestras";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaReporteEncuestasContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "documents") {
      window.document.title = "Listado de documentos";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaListadoDocumentosContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "adm_ips") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaIpsContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "tablero_pdd") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TableroPddContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "visor") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaVisorArchivosContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else {
      window.document.title = "Almera SGI";
      return (
        <div align="center">
          <NotFound animationData={animationData} />
          <div style={{ marginTop: 3 }}>
            <Alert
              variant="filled"
              align="center"
              sx={{ width: 300, marginTop: -50, position: "sticky" }}
              severity="error"
            >
              Ruta inexistente 410 <br /> {currentDateTime}
            </Alert>
          </div>
        </div>
      );
    }
  } else {
    if (route === "adm_tableroatenciones") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<AtencionesContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "rep_surveys") {
      window.document.title = "Reporte general de muestras";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaReporteEncuestasContainer />} />
          </Routes>
        </React.Fragment>
      );
    }

    if (route === "surveys") {
      window.document.title = "Encuestas";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaEncuestasContainer />} />
          </Routes>
        </React.Fragment>
      );
    }

    if (route === "visor-archivos") {
      window.document.title = "Visor de archivos";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<VisorContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "documents") {
      window.document.title = "Listado de documentos";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaListadoDocumentosContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "events") {
      window.document.title = "Almera SGI";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TableroEventosContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "supplier") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TableroContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "securityhub") {
      window.document.title = "Centro de seguridad";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaSecurityHubContainer />} />
          </Routes>
        </React.Fragment>
      );
    }

    if (route === "adminairbytes") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaPruebasContainer />} />
          </Routes>
        </React.Fragment>
      );
    }
    if (route === "adm_ips") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaIpsContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "tablero_pdd") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TableroPddContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "pdf_highlighter") {
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<HighlighterContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else if (route === "visor") {
      //  window.document.title = "Centro de seguridad";
      return (
        <React.Fragment>
          <Redirect instance={instance} route={route} />
          <Routes>
            <Route path="/" element={<TablaVisorArchivosContainer />} />
          </Routes>
        </React.Fragment>
      );
    } else {
      window.document.title = "Almera SGI";
      return (
        <div align="center">
          <NotFound animationData={animationData} />
          <div style={{ marginTop: 3 }}>
            <Alert
              variant="filled"
              align="center"
              sx={{ width: 300, marginTop: -50, position: "sticky" }}
              severity="error"
            >
              Endpoint inexistente 575 <br /> {currentDateTime}
            </Alert>
          </div>
        </div>
      );
    }
  }
}
function QueryParamsDemo() {
  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    setCurrentDateTime(formattedDateTime);
  }, []);

  const query = useQuery();
  const dispatch = useDispatch();
  localStorage.setItem("key", query.get("key"));
  const [codigo, setCodigo] = useState(0);

  let instance = query.get("instance");

  useEffect(() => {
    const tkreact = "tkreact_" + instance;

    if (
      Cookies.get(tkreact) === undefined ||
      Cookies.get(tkreact) === null ||
      Cookies.get(tkreact) === "null" ||
      Cookies.get(tkreact) === "undefined"
    ) {
      if (
        query.get("codigo") !== undefined &&
        query.get("codigo") !== null &&
        query.get("codigo") !== "undefined" &&
        query.get("codigo") !== "null" &&
        query.get("codigo").length > 0
      ) {
        QueryServiceJWT({
          codigo: query.get("codigo"),
          instance: localStorage.getItem("instance"),
        }).then((result) => {
          if (result.jwt !== undefined) {
            const setCookie = (name, value, options = {}) => {
              Cookies.set(name, value, options);
            };
            setCookie(tkreact, result.jwt, { expires: 1 }); // Expira en 1 día
          }
          setCodigo(200);
        });
      }
    }
  }, []);

  if (
    localStorage.getItem("hash") === null ||
    localStorage.getItem("hash") === "null" ||
    localStorage.getItem("hash") === undefined ||
    localStorage.getItem("hash") === "undefined"
  ) {
    localStorage.setItem("hash", query.get("hash"));
  }

  if (
    localStorage.getItem("claseId") === null ||
    localStorage.getItem("claseId") === "null" ||
    localStorage.getItem("claseId") === undefined ||
    localStorage.getItem("claseId") === "undefined"
  ) {
    localStorage.setItem("claseId", query.get("clase_id"));
  }

  if (
    localStorage.getItem("clase") === null ||
    localStorage.getItem("clase") === "null" ||
    localStorage.getItem("clase") === undefined ||
    localStorage.getItem("clase") === "undefined"
  ) {
    localStorage.setItem("clase", query.get("clase"));
  }

  if (
    localStorage.getItem("redirect") === null ||
    localStorage.getItem("redirect") === "null" ||
    localStorage.getItem("redirect") === undefined ||
    localStorage.getItem("redirect") === "undefined"
  ) {
    localStorage.setItem("redirect", query.get("redirect_uri"));
  }

  dispatch(addEndPoint(query.get("api")));
  localStorage.setItem("route", query.get("route"));
  let jwt = " ";

  if (
    query.get("route") === null ||
    query.get("route") === "null" ||
    query.get("route") === undefined ||
    query.get("route") === "undefined"
  ) {
    window.document.title = "Almera SGI";
    return (
      <div align="center">
        <NotFound animationData={animationData} />

        <Alert
          variant="filled"
          align="center"
          sx={{ width: 300, marginTop: -50, position: "sticky" }}
          severity="error"
        >
          Ruta inexistente 659 <br /> {currentDateTime}
        </Alert>
      </div>
    );
  }

  if (
    localStorage.getItem("hash") === null ||
    localStorage.getItem("hash") === "null" ||
    localStorage.getItem("hash") === undefined ||
    localStorage.getItem("hash") === "undefined"
  ) {
    localStorage.setItem("hash", query.get("hash"));
  }

  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === "null" ||
    localStorage.getItem("token") === undefined ||
    localStorage.getItem("token") === "undefined"
  ) {
    localStorage.setItem("token", query.get("token"));
  } else {
    if (
      localStorage.getItem("token") !== query.get("token") &&
      query.get("token") !== null &&
      query.get("token") !== undefined &&
      query.get("token") !== "null" &&
      query.get("token") !== "undefined"
    ) {
      localStorage.setItem("token", query.get("token"));
    }
  }

  if (
    localStorage.getItem("test") === null ||
    localStorage.getItem("test") === "null" ||
    localStorage.getItem("test") === undefined ||
    localStorage.getItem("test") === "undefined"
  ) {
    localStorage.setItem("test", query.get("test"));
  } else {
    if (
      localStorage.getItem("test") !== query.get("test") &&
      query.get("test") !== null &&
      query.get("test") !== undefined &&
      query.get("test") !== "null" &&
      query.get("test") !== "undefined"
    ) {
      localStorage.setItem("test", query.get("test"));
    }
  }

  if (
    (localStorage.getItem("instance") !== query.get("instance") &&
      localStorage.getItem("instance") === null) ||
    localStorage.getItem("instance") === "null" ||
    localStorage.getItem("instance") === undefined ||
    localStorage.getItem("instance") === "undefined"
  ) {
    localStorage.setItem("instance", query.get("instance"));
    instance = query.get("instance");
  } else {
    if (
      localStorage.getItem("instance") !== query.get("instance") &&
      query.get("instance") !== null &&
      query.get("instance") !== undefined &&
      query.get("instance") !== "null" &&
      query.get("instance") !== "undefined"
    ) {
      localStorage.setItem("instance", query.get("instance"));
      instance = query.get("instance");
    }
  }

  if (
    (localStorage.getItem("baseuri") !== query.get("baseuri") &&
      localStorage.getItem("baseuri") === null) ||
    localStorage.getItem("baseuri") === "null" ||
    localStorage.getItem("baseuri") === undefined ||
    localStorage.getItem("baseuri") === "undefined"
  ) {
    localStorage.setItem("baseuri", query.get("baseuri"));
  } else {
    if (
      localStorage.getItem("baseuri") !== query.get("baseuri") &&
      query.get("baseuri") !== null &&
      query.get("baseuri") !== undefined &&
      query.get("baseuri") !== "null" &&
      query.get("baseuri") !== "undefined"
    ) {
      localStorage.setItem("baseuri", query.get("baseuri"));
    }
  }

  const tkreact = "tkreact_" + instance;

  if (
    Cookies.get(tkreact) === undefined ||
    Cookies.get(tkreact) === null ||
    Cookies.get(tkreact) === "null" ||
    Cookies.get(tkreact) === "undefined"
  ) {
    if (
      query.get("codigo") !== undefined &&
      query.get("codigo") !== null &&
      query.get("codigo") !== "undefined" &&
      query.get("codigo") !== "null" &&
      query.get("codigo").length > 0 &&
      localStorage.getItem("baseuri") !== undefined &&
      localStorage.getItem("baseuri") !== null &&
      localStorage.getItem("baseuri") !== "undefined" &&
      localStorage.getItem("baseuri") !== "null" &&
      localStorage.getItem("baseuri").length > 0
    ) {
      if (codigo === 200) {
        if (
          Cookies.get(tkreact) === undefined ||
          Cookies.get(tkreact) === null ||
          Cookies.get(tkreact) === "null" ||
          Cookies.get(tkreact) === "undefined"
        ) {
          return (
            <div align="center">
              <NotFound animationData={animationData} />
              <div style={{ marginTop: 3 }}>
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300, marginTop: -50, position: "sticky" }}
                  severity="error"
                >
                  Token inexistente 760 <br /> {currentDateTime}
                </Alert>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <QueryParameters
                instance={instance}
                route={query.get("route")}
                currentDateTime={currentDateTime}
              />
            </div>
          );
        }
      } else {
        return <CircularProgress />;
      }
    } else {
      if (
        query.get("tk") !== null &&
        query.get("tk") !== undefined &&
        query.get("tk") !== "null" &&
        query.get("tk") !== "undefined" &&
        query.get("tk").length > 0
      ) {
        const setCookie = (name, value, options = {}) => {
          Cookies.set(name, value, options);
        };
        setCookie(tkreact, query.get("tk"), { expires: 1 });
      }
    }
  }

  localStorage.setItem("styleStatus", true);
  if (
    localStorage.getItem("endpoint") !== query.get("endpoint") &&
    query.get("endpoint") !== null &&
    query.get("endpoint") !== "null" &&
    query.get("endpoint") !== undefined &&
    query.get("endpoint") !== "undefined"
  ) {
    localStorage.setItem("endpoint", query.get("endpoint"));
  }

  if (
    localStorage.getItem("hash") === null ||
    localStorage.getItem("hash") === "null" ||
    localStorage.getItem("hash") === undefined ||
    localStorage.getItem("hash") === "undefined"
  ) {
    localStorage.setItem("hash", query.get("hash"));
  } else {
    if (
      localStorage.getItem("hash") !== query.get("hash") &&
      query.get("hash") !== null &&
      query.get("hash") !== undefined &&
      query.get("hash") !== "null" &&
      query.get("hash") !== "undefined"
    ) {
      localStorage.setItem("hash", query.get("hash"));
    }
  }

  if (
    localStorage.getItem("id") === null ||
    localStorage.getItem("id") === "null" ||
    localStorage.getItem("id") === undefined ||
    localStorage.getItem("id") === "undefined"
  ) {
    localStorage.setItem("id", query.get("id"));
  } else {
    if (
      localStorage.getItem("id") !== query.get("id") &&
      query.get("id") !== null &&
      query.get("id") !== undefined &&
      query.get("id") !== "null" &&
      query.get("id") !== "undefined"
    ) {
      localStorage.setItem("id", query.get("id"));
    }
  }

  if (
    localStorage.getItem("api") !== query.get("api") &&
    query.get("api") !== null &&
    query.get("api") !== "null" &&
    query.get("api") !== undefined &&
    query.get("api") !== "undefined"
  ) {
    localStorage.setItem("api", query.get("api"));
  }
  if (
    localStorage.getItem("apiDocuments") === null ||
    localStorage.getItem("apiDocuments") === "null" ||
    localStorage.getItem("apiDocuments") === undefined ||
    localStorage.getItem("apiDocuments") === "undefined"
  ) {
    localStorage.setItem("apiDocuments", query.get("api"));
  } else {
    if (
      localStorage.getItem("apiDocuments") !== query.get("api") &&
      query.get("api") !== null &&
      query.get("api") !== undefined &&
      query.get("api") !== "null" &&
      query.get("api") !== "undefined"
    ) {
      localStorage.setItem("apiDocuments", query.get("api"));
    }
  }
  if (
    localStorage.getItem("back") === null ||
    localStorage.getItem("back") === "null" ||
    localStorage.getItem("back") === undefined ||
    localStorage.getItem("back") === "undefined"
  ) {
    localStorage.setItem("back", query.get("back"));
  } else {
    if (
      localStorage.getItem("back") !== query.get("back") &&
      query.get("back") !== null &&
      query.get("back") !== undefined &&
      query.get("back") !== "null" &&
      query.get("back") !== "undefined"
    ) {
      localStorage.setItem("back", query.get("back"));
    }
  }
  if (
    localStorage.getItem("host") === null ||
    localStorage.getItem("host") === "null" ||
    localStorage.getItem("host") === undefined ||
    localStorage.getItem("host") === "undefined"
  ) {
    localStorage.setItem("host", query.get("host"));
  }
  if (
    localStorage.getItem("endpointCuenta") === null ||
    localStorage.getItem("endpointCuenta") === "null" ||
    localStorage.getItem("endpointCuenta") === undefined ||
    localStorage.getItem("endpointCuenta") === "undefined"
  ) {
    if (query.get("route") === "adm_account") {
      localStorage.setItem("endpointCuenta", query.get("api"));
    }
  }

  try {
    const instance = localStorage.getItem("instance");
    const tkreact = "tkreact_" + instance;
    jwt = Cookies.get(tkreact);
    jwt = jwt.slice(8);
    jwt = "Bearer " + jwt;
    jwt1(Cookies.get(tkreact));
  } catch (e) {
    const instance = localStorage.getItem("instance");
    const tkreact = "tkreact_" + instance;

    if (
      Cookies.get(tkreact) === null ||
      Cookies.get(tkreact) === "null" ||
      Cookies.get(tkreact) === undefined ||
      Cookies.get(tkreact) === "undefined"
    ) {
      if (query.get("route") === "visor-archivos") {
        window.document.title = "Visor de archivos";
        return (
          <React.Fragment>
            <Redirect instance={instance} route={"visor-archivos"} />
            <Routes>
              <Route path="/" element={<VisorContainer />} />
            </Routes>
          </React.Fragment>
        );
      } else {
        if (
          localStorage.getItem("tkreact") === undefined ||
          localStorage.getItem("tkreact") === null ||
          localStorage.getItem("tkreact") === "null"
        ) {
          return (
            <div align="center">
              <NotFound animationData={animationData} />
              <div style={{ marginTop: 3 }}>
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300, marginTop: -50, position: "sticky" }}
                  severity="error"
                >
                  Token inexistente 760 <br /> {currentDateTime}
                </Alert>
              </div>
            </div>
          );
        }
      }
    }
  }

  if (
    localStorage.getItem("instance") === null ||
    localStorage.getItem("instance") === "null"
  ) {
    if (jwt !== " ") {
      return (
        <div>
          <QueryParameters
            instance={instance}
            route={query.get("route")}
            currentDateTime={currentDateTime}
          />
        </div>
      );
    } else {
      if (
        localStorage.getItem("tkreact") === undefined ||
        localStorage.getItem("tkreact") === null
      ) {
        return (
          <div align="center">
            <NotFound animationData={animationData} />
            <div style={{ marginTop: 3 }}>
              <Alert
                variant="filled"
                align="center"
                sx={{ width: 300, marginTop: -50, position: "sticky" }}
                severity="error"
              >
                Token inexistente 906 <br /> {currentDateTime}
              </Alert>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <QueryParameters
              instance={instance}
              route={query.get("route")}
              currentDateTime={currentDateTime}
            />
          </div>
        );
      }
    }
  } else {
    if (jwt) {
      return (
        <div>
          <QueryParameters
            instance={instance}
            route={query.get("route")}
            currentDateTime={currentDateTime}
          />
        </div>
      );
    } else {
      if (localStorage.getItem("tkreact") !== null) {
        jwt = localStorage.getItem("tkreact");
        jwt = jwt.slice(8);
        return (
          <div>
            <QueryParameters
              instance={instance}
              route={query.get("route")}
              currentDateTime={currentDateTime}
            />
          </div>
        );
      } else {
        if (
          localStorage.getItem("tkreact") === undefined ||
          localStorage.getItem("tkreact") === null
        ) {
          return (
            <div align="center">
              <NotFound animationData={animationData} />
              <div style={{ marginTop: 3 }}>
                <Alert
                  variant="filled"
                  align="center"
                  sx={{ width: 300, marginTop: -50, position: "sticky" }}
                  severity="error"
                >
                  Token inexistente 945 <br /> {currentDateTime}
                </Alert>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <QueryParameters
                instance={instance}
                route={query.get("route")}
                currentDateTime={currentDateTime}
              />
            </div>
          );
        }
      }
    }
  }
}

function QueryParameters({ route, currentDateTime, instance }) {
  return <div>{llamado(route, currentDateTime, instance)}</div>;
}
