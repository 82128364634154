import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import {
  TableCell,
  TableFooter,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    borderBottom: "none",
    color: "black",
    fontSize: 12,
    fontWeight: "bolder",
  },
}));

const TablaTablero = ({ opcionMenu, datosTabla, tituloTablero }) => {
  if (datosTabla.length !== undefined && datosTabla.length > 0) {
    const getMuiTheme = () =>
      createTheme({
        components: {
          MuiTableCell: {
            styleOverrides: {
              root: {
                padding: "8px",
              },
            },
          },
          MuiToolbar: {
            styleOverrides: {
              regular: {
                minHeight: "8px",
              },
            },
          },
        },
      });

    const classes = useStyles();

    let idTotal = 0;
    let valueTotal = 0;
    let percentTotal = 0;

    datosTabla.forEach((x) => {
      idTotal = parseInt(idTotal) + parseInt(x.id);
      valueTotal = parseInt(valueTotal) + parseInt(x.value);
      percentTotal = parseFloat(percentTotal) + parseFloat(x.percent);
    });

    valueTotal = valueTotal.toString();

    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: true,
          display: false,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return <b key={columnMeta.index}>{columnMeta.label}</b>;
          },
        },
      },
      {
        name: "name",
        label: tituloTablero,
        options: {
          filter: true,
          display: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return <b key={columnMeta.index}>{columnMeta.label}</b>;
          },
        },
      },

      {
        name: "value",
        label: "Valor ",
        options: {
          filter: true,
          display: true,
          customHeadLabelRender: (columnMeta) => {
            return <b key={columnMeta.index}>{columnMeta.label}</b>;
          },
          setCellProps: (value) => {
            return {
              style: {
                width: 100,
              },
            };
          },
          customBodyRenderLite: (value) => (
            <div key={value} align="right">
              {datosTabla[value].value}
            </div>
          ),
        },
      },

      {
        name: "percent",
        label: "%",
        options: {
          filter: false,
          display: true,
          customHeadLabelRender: (columnMeta) => {
            return <b key={columnMeta.index}>{columnMeta.label}</b>;
          },

          setCellProps: (value) => {
            return {
              style: {
                width: 100,
              },
            };
          },
          customBodyRenderLite: (value) => (
            <div key={value} align="right">
              {datosTabla[value].percent + "%"}
            </div>
          ),
        },
      },
    ];

    const options = {
      expandableRowsOnClick: true,
      responsive: "standard",
      searchPlaceholder: "Buscar ",
      tableBodyHeight: "estándar",
      filterType: "checkbox",
      tableBodyMaxHeight: "estándar",
      onRowsPerPageChange: 10,
      print: false,
      viewColumns: true,
      filter: false,
      selectableRows: "none",
      download: true,

      downloadOptions: {
        filename: opcionMenu.name + ".xlsx",
      },

      onDownload: (buildHead, buildBody, columns, values) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const json = values.reduce((result, val) => {
          const temp = {};
          val.data.forEach((v, idx) => {
            temp[columns[idx].label] = v;
          });
          result.push(temp);
          return result;
        }, []);

        const fileName = opcionMenu.name;
        const ws = utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        // cancel default  CSV download from table
        return false;
      },

      textLabels: {
        body: {
          noMatch: "Registros no encontrados",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
          sortable: true,
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Registros por página:",
          displayRows: "de",
          onPageChange: true,
        },
        toolbar: {
          search: "Buscar",
          viewColumns: "Ver columnas",
          downloadCsv: "Descargar",
          filterTable: "Filtrar",
        },
        filter: {
          all: "Todos",
          title: "Filtrar",
          reset: "Reiniciar",
        },

        viewColumns: {
          title: "Mostrar columnas",
        },
      },
      customTableBodyFooterRender: (opts) => {
        return (
          <>
            {datosTabla.length > 0 && (
              <TableFooter className={classes.footerCell}>
                <TableRow>
                  {opts.columns.map((col, index) => {
                    if (col.display === "true") {
                      if (col.name === "name") {
                        return (
                          <TableCell key={index} className={classes.footerCell}>
                            {"Total"}
                          </TableCell>
                        );
                      } else if (col.name === "id") {
                        return (
                          <TableCell
                            key={index}
                            className={classes.footerCell}
                          ></TableCell>
                        );
                      } else if (col.name === "value") {
                        return (
                          <TableCell
                            align="right"
                            key={index}
                            className={classes.footerCell}
                          >
                            {valueTotal}
                          </TableCell>
                        );
                      } else if (col.name === "percent") {
                        return (
                          <TableCell
                            align="right"
                            key={index}
                            className={classes.footerCell}
                          >
                            {percentTotal.toFixed(2) + "%"}
                          </TableCell>
                        );
                      }
                    }
                    return null;
                  })}
                </TableRow>
              </TableFooter>
            )}
          </>
        );
      },
    };

    return (
      <div>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title="Datos"
            data={datosTabla}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    );
  } else {
    return "";
  }
};

export default TablaTablero;
